// bottom tab navigation
export const TEST = "test";
export const GOAL = "goal";
export const CONSULT = "counsel";

// 오늘의 목표
export const 우울 = 1;
export const 불안 = 2;
export const 걱정 = 3;
export const 스트레스 = 4;
